<script>
export default {
    props: {
        flag: {}
    }
};
</script>

<template>
    <i
        class="fas"
        :class="{
            'fa-flag text-warning': flag == 'orange',
            'fa-flag text-danger': flag == 'red'
        }"
    ></i>
</template>
