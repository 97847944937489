<script>
import Cloner from "@/libraries/Cloner";
import VPicker from "./VPicker";
import VDependencyInput from "./VDependencyInput";
import VPadlock from "../VPadlock";
import { el } from "date-fns/locale";

export default {
    props: {
        milestones: {},
        initDna: {
            description:
                "Array for seeding the dna internal array (watcher that triggers update)"
        },
        isRW: {
            type: Boolean,
            default: false,
            description: "Whether editing is allowed"
        },
        errors: {}
    },
    components: { VPicker, VDependencyInput, VPadlock },
    data() {
        return {
            loading: true,
            draft: [],
            dna: [],
            isExternalUpdate: false,
            isInitialization: true,
            focus: 0
        };
    },
    watch: {
        /**
         * Watch for external updates
         */
        milestones: {
            deep: true,
            handler: function(nV) {
                // console.log("MILE WATCHER");
                this.isExternalUpdate = true;
                this.draft = Cloner.clone(nV);
            }
        },
        initDna: {
            deep: true,
            handler(nV) {
                if (Array.isArray(nV)) {
                    this.dna = Cloner.clone(nV);
                }
            }
        },
        /**
         * Our own updates
         */
        draft: {
            deep: true,
            handler(nV) {
                if (!this.isExternalUpdate && !this.isInitialization) {
                    this.rerun();
                } else {
                    this.isExternalUpdate = false;
                }
            }
        }
    },
    created() {
        this.isExternalUpdate = true;
        this.draft = Cloner.clone(this.milestones);
        this.isInitialization = false;
        this.loading = false;
    },
    methods: {
        /**
         * Rerun preview
         */
        rerun() {
            if (!this.validateBeforePreview()) {
                return;
            }
            // Run only dates.
            this.runPreview("dates");
        },
        onFocus(m_ident) {
            this.focus = m_ident;
        },
        onBlur() {
            this.focus = 0;
            this.runPreview("dates");
        },
        /**
         * Check if dependency formats are OK.
         */
        validateBeforePreview() {
            for (const el of this.draft) {
                if (!this.checkDependency(el.dependency)) {
                    return false;
                }
            }

            if (this.focus != 0) {
                return false;
            }

            return true;
        },
        /**
         * Validate dependency format
         * @param dependency
         */
        checkDependency(dependency) {
            if (dependency === "" || dependency === null) {
                return true;
            }

            const regex = /^([0-9]+)([SE])([-+]\d+)?$/;
            const matches = dependency.match(regex);

            if (matches) {
                return true;
            } else {
                // Return a default value if no match is found
                return false;
            }
        },
        /**
         * Check if auto-calc conditions are met
         */
        shouldAutoCalc() {
            // only 1 date on start can be set for auto mode.
            let countStart = 0;
            let countEnd = 0;
            this.draft.forEach(elem => {
                if (elem.start_date) {
                    countStart++;
                }
                if (elem.end_date) {
                    countEnd++;
                }
            });

            if (countStart === 1 && countEnd === 0) {
                return true;
            } else {
                return false;
            }
        },
        /**
         * Check if everything is ok
         * @param mode
         */
        runPreview(mode) {
            this.$emit("preview", {
                milestones: this.draft,
                mode: mode,
                dna: this.dna
            });
        },
        /**
         * Cleanup all dates
         */
        cleanDates() {
            this.$swal({
                icon: "warning",
                text: this.$t("planning.clean_dates_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    this.draft.forEach(elem => {
                        elem.start_date = null;
                        elem.end_date = null;
                        elem.lock_start_date = null;
                        elem.lock_end_date = null;
                    });
                }
            });
        }
    }
};
</script>

<template>
    <div v-if="!loading" class="gantt-editor-container">
        <div class="gantt-editor-container__buttons">
            <div>
                <base-button
                    size="sm"
                    type="danger"
                    outline
                    @click="cleanDates"
                    :disabled="!isRW"
                    ><i class="far fa-trash"></i
                ></base-button>
            </div>
            <div>
                <span class="style-master">
                    {{ $t("planning.style_editing") }}</span
                >
                <span class="style-baseline">
                    {{ $t("planning.style_baseline") }}</span
                >
            </div>
        </div>
        <div class="cont">
            <div class="editor">
                <div class="editor__id"></div>

                <div class="editor__inp">
                    {{ $t("planning.dates") }}
                </div>
                <div class="editor__dep">
                    {{ $t("planning.dep") }}
                </div>
            </div>
            <div
                v-for="m in draft"
                :key="m.id"
                class="editor"
                :class="{ 'has-error': 'errors' in errors }"
            >
                <div class="editor__id">M{{ m.m_ident }}</div>

                <div class="editor__inp">
                    <div>
                        <v-picker
                            v-model="m.start_date"
                            type="start"
                            :duration="m.duration"
                            :dependent="m.end_date"
                            :disabled="!isRW"
                            @update-dependent="m.end_date = $event"
                        />
                        <v-padlock
                            class="ml-1"
                            v-model="m.lock_start_date"
                            :false-value="null"
                            :disabled="!isRW"
                        />
                    </div>
                    <div>
                        <v-picker
                            v-model="m.end_date"
                            type="end"
                            :duration="m.duration"
                            :dependent="m.start_date"
                            :disabled="!isRW"
                            @update-dependent="m.start_date = $event"
                        />
                        <v-padlock
                            class="ml-1"
                            v-model="m.lock_end_date"
                            :false-value="null"
                            :disabled="!isRW"
                        />
                    </div>
                </div>

                <div class="editor__dep">
                    <v-dependency-input
                        v-model.lazy="m.dependency"
                        @focus="onFocus(m.m_ident)"
                        @blur="onBlur"
                        :disabled="!isRW"
                    />

                    <v-padlock
                        class="ml-1"
                        v-model="dna"
                        :true-value="m.m_ident"
                        :false-value="null"
                        :disabled="!isRW"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.gantt-editor-container {
    &__buttons {
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
        /* Justifying heights */
        margin-bottom: 20px;
    }
}
.editor.has-error {
    input {
        border: 1px solid red !important;
    }
    .editor__id {
        color: red !important;
    }
}

.cont {
    .form-group {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 0px;
        margin-bottom: 0px;
        input.form-control {
            height: 25px !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.style-baseline {
    background: $gray-300;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
    color: black;
    font-weight: bold;
    font-size: 13px;
    margin-left: 8px;
}

.style-master {
    background: $default;
    opacity: 0.6;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 13px;
}

.cont {
    border-right: 5px solid $gray-600;

    .editor {
        font-size: 12px;
        border-bottom: 1px dotted $gray-600;
        padding: 5px;
        line-height: 20px; /* Align text vertically */
        display: flex;

        &__id {
            font-weight: 600;
            flex: 0 0 40px;
            text-align: center;
        }

        &__inp {
            display: flex;
            justify-content: space-evenly;
            flex: 1 1;
            flex-direction: row;
        }

        &__dep {
            text-align: center;
            flex: 0 0 105px;
            input[type="text"] {
                width: 46px;
            }
        }
    }
}
</style>
