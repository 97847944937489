<script>
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";

import isLeapYear from "dayjs/plugin/isLeapYear";
dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);

export default {
    name: "WeekRangePicker",
    props: {
        initialYear: {
            type: Number,
            default: dayjs().year()
        },
        type: {
            type: String
        },
        duration: {},
        disabled: {
            type: Boolean,
            default: false
        },
        value: {},
        dependent: {
            description: "Dependent value used for validation"
        }
    },
    data() {
        return {
            currentYear: 0,
            showPicker: false,
            weeks: []
        };
    },
    computed: {
        displayDate() {
            if (this.value) {
                return (
                    "" +
                    dayjs(this.value).isoWeek() +
                    "-" +
                    dayjs(this.value).format("YYYY")
                );
            } else {
                return "";
            }
        },
        selectedWeek() {
            if (this.value) {
                return parseInt(dayjs(this.value).isoWeek());
            } else {
                return 0;
            }
        },
        dependentWY() {
            if (this.dependent) {
                const dt = dayjs(this.dependent);
                return parseInt(dt.isoWeek()) + "." + dt.year();
            } else {
                return "";
            }
        }
    },
    watch: {
        value: function(nv) {
            this.adjustCurrentYear(nv);
        }
    },
    created() {
        this.adjustCurrentYear(this.value);
    },
    methods: {
        adjustCurrentYear(nV) {
            if (nV) {
                this.currentYear = dayjs(nV).year();
            } else {
                this.currentYear = dayjs().year();
            }
        },
        resolveDate(week, year) {
            if (this.type === "start") {
                return dayjs()
                    .day(1)
                    .year(year)
                    .isoWeek(week)
                    .format("DD.MM");
            } else {
                return dayjs()
                    .day(5)
                    .year(year)
                    .isoWeek(week)
                    .format("DD.MM");
            }
        },
        cleanDate() {
            this.$emit("input", "");
            this.showPicker = false;
        },
        selectDate(week, year) {
            if (this.type === "start") {
                this.$emit(
                    "input",
                    dayjs()
                        .day(1)
                        .year(year)
                        .isoWeek(week)
                        .format("YYYY-MM-DD")
                );

                if (this.type == "start" && parseInt(this.duration) > 0) {
                    this.$emit(
                        "update-dependent",
                        dayjs()
                            .day(5)
                            .year(year)
                            .isoWeek(week)
                            .add(this.duration, "week")
                            .format("YYYY-MM-DD")
                    );
                }
            } else {
                this.$emit(
                    "input",
                    dayjs()
                        .day(5)
                        .year(year)
                        .isoWeek(week)
                        .format("YYYY-MM-DD")
                );

                if (this.type == "end" && parseInt(this.duration) > 0) {
                    this.$emit(
                        "update-dependent",
                        dayjs()
                            .day(1)
                            .year(year)
                            .isoWeek(week)
                            .subtract(this.duration, "week")
                            .format("YYYY-MM-DD")
                    );
                }
            }

            this.showPicker = false;
        },
        /**
         * Simply pop it up
         * Stop propagation is crucial, otherwise the event is bubbled and breaks
         * the click outside.
         * @param event
         */
        togglePicker(event) {
            if (this.disabled) {
                return;
            }
            event.stopPropagation();
            // if (this.value) {
            //     d = dayjs(this.value);
            //     this.currentYear = d.year();
            // }
            this.generateWeeks();
            this.showPicker = !this.showPicker;
        },
        /**
         * Generate weeks for a given year
         * Must be triggered on year change
         */
        generateWeeks() {
            const totalWeeks = dayjs(
                `${this.currentYear}-01-01`
            ).isoWeeksInYear(); // Total weeks in the year
            this.weeks = Array.from({ length: totalWeeks }, (_, i) => i + 1);
        },
        /**
         * Change the year
         * @param delta
         */
        changeYear(delta) {
            this.currentYear += delta;
            this.generateWeeks();
        },
        /**
         * Handler for the click-outside
         */
        closeWindow(ev) {
            this.showPicker = false;
        },
        matchDependent(week, currentYear) {
            if (
                this.dependent &&
                this.dependentWY === `${week}.${currentYear}`
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>

<template>
    <div class="v-picker-week-range-picker" ref="picker">
        <!-- Toggle button to show selected range or placeholder -->

        <input
            type="text"
            :value="displayDate"
            @click="togglePicker"
            :disabled="disabled"
        />

        <!-- Popup for selecting week and year -->
        <div v-if="showPicker" class="picker-popup">
            <div v-click-outside="closeWindow">
                <!-- Year switcher -->
                <div class="year-switcher">
                    <base-button
                        size="sm"
                        @click="changeYear(-1)"
                        type="primary"
                        outline
                        ><i class="far fa-arrow-left"></i
                    ></base-button>
                    <div class="year-switcher__yr">{{ currentYear }}</div>
                    <base-button
                        size="sm"
                        @click="changeYear(1)"
                        type="primary"
                        outline
                        ><i class="far fa-arrow-right"></i
                    ></base-button>
                </div>

                <!-- Week grid -->
                <div class="weeks-grid">
                    <div
                        class="week"
                        v-for="week in weeks"
                        :key="week"
                        @click="selectDate(week, currentYear)"
                        :class="{
                            selected: parseInt(week) === selectedWeek,
                            'dependent-date': matchDependent(week, currentYear)
                        }"
                    >
                        {{ week }}
                        <div class="week__dt">
                            {{ resolveDate(week, currentYear) }}
                        </div>
                    </div>
                </div>

                <div class="dependent__demo">
                    <div class="week dependent-date">1</div>
                    -
                    <template v-if="type === 'start'">{{
                        $t("planning.dependent_date_end")
                    }}</template>
                    <template v-else-if="type === 'end'">{{
                        $t("planning.dependent_date_start")
                    }}</template>
                </div>

                <!-- Apply button -->
                <div class="week-buttons text-center">
                    <base-button
                        size="sm"
                        type="danger"
                        outline
                        @click="cleanDate"
                        ><i class="far fa-trash"></i>
                        {{ $t("planning.remove_date") }}</base-button
                    >
                    <base-button
                        size="sm"
                        type="white"
                        @click="showPicker = false"
                        ><i class="far fa-times"></i>
                        {{ $t("planning.close") }}</base-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.v-picker-week-range-picker {
    position: relative;
    display: inline-block;

    input {
        border-radius: 6px;
        border: 1px solid $gray-600;
        font-size: 12px;
        width: 60px;
        text-align: center;
        cursor: pointer;
    }

    .year-switcher {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $gray-300;
        padding-top: 10px;

        button {
            margin-right: 0px !important;
        }

        &__yr {
            font-size: 18px;
            font-weight: 600;

            width: 90px;
            text-align: center;
        }
    }

    .week-buttons {
        border-top: 1px solid $gray-300;
        padding-top: 15px;
        padding-bottom: 10px;
        margin-top: 10px;
    }

    .weeks-grid {
        padding-left: 5px;
        display: flex;
        flex-wrap: wrap;

        width: 320px;
        overflow-y: auto;
    }

    .week {
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        font-weight: bold;
        border: 1px solid $gray-500;
        border-radius: 6px;
        cursor: pointer;
        width: 34px;
        padding: 2px;
        color: $primary;

        &__dt {
            font-weight: normal;
            color: $gray-600;
            margin-top: -5px;
            font-size: 9px;
        }
    }

    .picker-popup {
        position: absolute;
        border: 1px solid #ccc;
        background: white;
        z-index: 1000;
        top: 0;
        left: 0;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.8);
        padding: 10px;
        border-radius: 4px;
    }

    .week:hover {
        background: #e0e0e0;
    }

    .week.selected {
        background: $primary;
        color: white;
        font-weight: bold;

        .week__dt {
            color: white;
        }
    }

    .week.dependent-date {
        border: 3px dashed $primary;
    }

    .dependent__demo {
        .week {
            font-size: 10px;
            margin-bottom: 0px;
        }
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding-left: 5px;
        font-size: 10px;
    }
}
</style>
