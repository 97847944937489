<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {}
    },
    methods: {
        checkDependency(dependency) {
            if (dependency === "" || dependency === null) {
                return true;
            }

            const regex = /^([0-9]+)([SE])([-+]\d+)?$/;
            const matches = dependency.match(regex);

            if (matches) {
                return true;
            } else {
                // Return a default value if no match is found
                return false;
            }
        }
    }
};
</script>

<template>
    <input
        type="text"
        :value="value"
        @input="$emit('input', $event.target.value)"
        class="input-dep"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        :class="{ disabled, 'has-error': !checkDependency(value) }"
    />
</template>

<style lang="scss">
input.input-dep {
    border-radius: 6px;
    border: 1px solid $gray-600;
    font-size: 12px;
    width: 60px;
    text-align: center;

    &.disabled {
        background: $gray-200 !important;
        cursor: not-allowed;
    }

    &.has-error {
        border: 2px solid red !important;
    }
}
</style>
