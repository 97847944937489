<script>
import Cloner from "@/libraries/Cloner";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import minMax from "dayjs/plugin/minMax";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);

dayjs.extend(isoWeek);
dayjs.extend(minMax);
dayjs.extend(quarterOfYear);

export default {
    props: {
        milestones: {
            type: Array,
            required: true
        },
        editing: {
            type: Boolean,
            required: true
        },
        showBaseline: {
            type: Boolean,
            default: false
        },
        errors: {}
    },
    data() {
        return {
            scale: 1, // Controls the zoom level
            isDragging: false,
            dragStartX: 0,
            scrollStartX: 0,
            headerHeight: 40,
            rowHeight: 35,
            milestoneNameWidth: 50,
            baseline: [],
            scaleUnit: "week",
            isClean: false
        };
    },
    computed: {
        upperScaleUnits() {
            const units = [];
            let start = this.projectStartDate.startOf(this.scaleUnit); // dynamic - scale dependent
            const end = this.projectEndDate.endOf(this.scaleUnit);

            while (start.isBefore(end) || start.isSame(end)) {
                const unitEnd = start.clone().endOf(this.scaleUnit);
                const offsetDays = start.diff(this.projectStartDate, "day");
                const durationDays = unitEnd.diff(start, "day") + 1;

                units.push({
                    start: start.clone(),
                    end: unitEnd.clone(),
                    offsetDays,
                    durationDays,
                    label: this.getScaleUnitLabel(this.scaleUnit, start)
                });

                start = unitEnd.add(1, "day"); // next unit
            }
            return units;
        },
        lowerScaleUnits() {
            const units = [];
            const projectStart = this.projectStartDate.startOf("year"); // STart 01.01
            const projectEnd = this.projectEndDate.endOf("year"); // end 31.12

            let currentYearStart = projectStart.clone();
            while (
                currentYearStart.isBefore(projectEnd) ||
                currentYearStart.isSame(projectEnd)
            ) {
                const currentYearEnd = currentYearStart.clone().endOf("year");

                units.push({
                    start: currentYearStart.clone(),
                    end: currentYearEnd.clone(),
                    offsetDays: currentYearStart.diff(
                        this.projectStartDate,
                        "day"
                    ),
                    durationDays:
                        currentYearEnd.diff(currentYearStart, "day") + 1,
                    label: currentYearStart.format("YYYY")
                });

                currentYearStart = currentYearEnd.add(1, "day"); // Next year
            }

            return units;
        },

        getScaleUnitLabel() {
            return (unit, date) => {
                switch (unit) {
                    case "week":
                        return `${date.week()}`; // Get the week number
                    case "month":
                        return date.format("MMM"); // Format month as abbreviated name
                    case "quarter":
                        return `Q${date.quarter()}`; // Get the quarter number
                    case "year":
                        return date.format("YYYY"); // Format year as 4-digit year
                    default:
                        return "";
                }
            };
        },
        weekWidth() {
            return 15 * this.scale;
        },
        projectStartDate() {
            const dates = this.milestones
                .filter(m => m.start_date)
                .map(m => dayjs(m.start_date));
            if (dates.length === 0) {
                return dayjs();
            }
            return dayjs.min(dates);
        },
        projectEndDate() {
            const dates = this.milestones
                .filter(m => m.end_date)
                .map(m => dayjs(m.end_date));
            if (dates.length === 0) {
                return dayjs();
            }
            return dayjs.max(dates);
        },
        totalWeeks() {
            // Calculate the total number of weeks based on the selected scale
            const durationInWeeks =
                this.projectEndDate.diff(this.projectStartDate, "week") + 2;
            switch (this.scaleUnit) {
                case "week":
                    return durationInWeeks;
                case "month":
                    return Math.ceil(durationInWeeks / 4);
                case "quarter":
                    return Math.ceil(durationInWeeks / 13);
                case "year":
                    return Math.ceil(durationInWeeks / 52);
                default:
                    return durationInWeeks;
            }
        },
        dayWidth() {
            return Number(2 * this.scale).toFixed(2); // Adjust 2 to set the base width per day
        },
        totalChartWidth() {
            const totalDays =
                this.projectEndDate.diff(this.projectStartDate, "day") + 1;
            return this.milestoneNameWidth + totalDays * this.dayWidth;
        },
        totalChartHeight() {
            return this.headerHeight + this.milestones.length * this.rowHeight;
        }
    },
    watch: {
        milestones: {
            deep: true,
            handler(n) {
                this.checkIsClean();
            }
        }
    },
    created() {
        this.baseline = Cloner.clone(this.milestones);
    },
    methods: {
        checkIsClean() {
            // only 1 date on start can be set for auto mode.
            let countStart = 0;
            let countEnd = 0;
            this.milestones.forEach(elem => {
                if (elem.start_date) {
                    countStart++;
                }
                if (elem.end_date) {
                    countEnd++;
                }
            });

            if (countStart === 0 && countEnd === 0) {
                this.isClean = true;
            } else {
                this.isClean = false;
            }
        },
        setScaleUnit(unit) {
            this.scaleUnit = unit;
        },
        getWeekLabelWeek(weekIndex) {
            const date = this.projectStartDate.add(weekIndex, "week");
            return `${date.isoWeek()}`;
        },
        getWeekLabelYear(weekIndex, doShort = false) {
            const date = this.projectStartDate.add(weekIndex, "week");
            if (doShort) {
                return `${date.format("YY")}`;
            } else {
                return `${date.format("YYYY")}`;
            }
        },
        getBarStyle(milestone) {
            const startDate = dayjs(milestone.start_date);
            const endDate = dayjs(milestone.end_date);
            const projectStart = this.projectStartDate;
            const projectStartUnit = projectStart.startOf(this.scaleUnit);
            const initialOffsetDays = projectStartUnit.diff(
                projectStart,
                "day"
            );

            const offsetDays =
                startDate.diff(projectStart, "day") - initialOffsetDays;
            const durationDays = endDate.diff(startDate, "day") + 1;

            const left = offsetDays * this.dayWidth;
            const width = durationDays * this.dayWidth;

            return {
                left: `${left}px`,
                width: `${width}px`
            };
        },
        zoomIn() {
            this.scale += 0.2;
        },
        zoomOut() {
            if (this.scale > 0.4) {
                this.scale -= 0.2;
            }
        },
        onMouseDown(event) {
            this.isDragging = true;
            this.dragStartX = event.clientX;
            this.scrollStartX = this.$refs.ganttChartContainer.scrollLeft;
        },
        onMouseMove(event) {
            if (this.isDragging) {
                const deltaX = event.clientX - this.dragStartX;
                this.$refs.ganttChartContainer.scrollLeft =
                    this.scrollStartX - deltaX;
            }
        },
        onMouseUp() {
            this.isDragging = false;
        },
        onTouchStart(event) {
            if (event.touches.length === 1) {
                this.isDragging = true;
                this.dragStartX = event.touches[0].clientX;
                this.scrollStartX = this.$refs.ganttChartContainer.scrollLeft;
            }
        },
        onTouchMove(event) {
            if (this.isDragging && event.touches.length === 1) {
                const deltaX = event.touches[0].clientX - this.dragStartX;
                this.$refs.ganttChartContainer.scrollLeft =
                    this.scrollStartX - deltaX;
            }
        },
        onTouchEnd() {
            this.isDragging = false;
        },
        onWheel(event) {
            event.preventDefault();
            const delta = event.deltaY || event.wheelDelta;

            if (delta > 0) {
                // Scrolling down, zoom out
                this.zoomOut();
            } else {
                // Scrolling up, zoom in
                this.zoomIn();
            }
        },
        emitShowEditing() {
            if (this.editing) {
                this.$emit("show-editing", false);
            } else {
                this.$emit("show-editing", true);
            }
        }
    }
};
</script>

<template>
    <div>
        <!-- After cleaning up -->
        <div v-if="isClean">
            <div class="text-center mt-6 alert alert-warning mx-6">
                <h1>{{ $t("planning.empty_plan_nothing_to_show") }}</h1>
                <div class="mt-4">
                    <i class="far fa-star-half-alt fa-5x"></i>
                </div>
            </div>
        </div>
        <!-- In case there are validation errors -->
        <div v-else-if="'errors' in errors">
            <div class="mx-6 mt-6 error-box-plan">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="text-center text-danger">
                        <i class="far fa-exclamation-triangle fa-4x"></i>
                    </div>
                    <div class="text-left ml-5">
                        <ul class="text-bold text-danger">
                            <li
                                v-for="(error, index) in errors.errors.data"
                                :key="index"
                            >
                                <template v-if="Array.isArray(error)"
                                    ><span v-for="(e, i) in error">{{
                                        Object.values(e)[0]
                                    }}</span>
                                </template>
                                <template v-else>{{ error }}</template>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Graph -->
        <div class="gantt-visualization" v-else>
            <div class="gantt-controls">
                <!-- Hide/show button -->
                <div>
                    <base-button
                        size="sm"
                        @click="emitShowEditing"
                        type="primary"
                        ><i
                            class="far fa-fw"
                            :class="{
                                'fa-chevron-right': !editing,
                                'fa-chevron-left': editing
                            }"
                        ></i
                    ></base-button>
                </div>

                <!-- Scale control buttons -->
                <div>
                    <base-button size="sm" @click="setScaleUnit('week')">{{
                        $t("planning.unit_weeks")
                    }}</base-button>
                    <base-button size="sm" @click="setScaleUnit('month')">{{
                        $t("planning.unit_months")
                    }}</base-button>
                    <base-button size="sm" @click="setScaleUnit('quarter')">{{
                        $t("planning.unit_quarters")
                    }}</base-button>
                    <!-- Zoom Controls -->
                    <base-button
                        size="sm"
                        type="primary"
                        outline
                        @click="zoomOut"
                        ><i class="far fa-search-minus fa-fw"></i
                    ></base-button>
                    <base-button
                        size="sm"
                        type="primary"
                        outline
                        @click="zoomIn"
                        ><i class="far fa-search-plus fa-fw"></i
                    ></base-button>
                </div>
            </div>
            <div
                class="gantt-chart-container"
                ref="ganttChartContainer"
                @mousedown="onMouseDown"
                @mousemove="onMouseMove"
                @mouseup="onMouseUp"
                @mouseleave="onMouseUp"
                @touchstart="onTouchStart"
                @touchmove="onTouchMove"
                @touchend="onTouchEnd"
                @wheel.prevent="onWheel"
            >
                <!-- Gantt Chart -->
                <div
                    class="gantt-chart"
                    ref="ganttChart"
                    :style="{
                        width: totalChartWidth + 'px',
                        height: totalChartHeight + 'px'
                    }"
                >
                    <!-- Gantt Header -->
                    <div
                        class="gantt-header"
                        :style="{
                            width: totalChartWidth + 'px',
                            left: !editing ? milestoneNameWidth + 'px' : '20px'
                        }"
                    >
                        <!-- Upper Scale Units -->
                        <div class="gantt-header-row">
                            <div
                                class="gantt-cell"
                                v-for="(unit, index) in upperScaleUnits"
                                :key="'upper-' + index"
                                :style="{
                                    left: unit.offsetDays * dayWidth + 'px',
                                    width: unit.durationDays * dayWidth + 'px'
                                }"
                            >
                                {{ unit.label }}
                            </div>
                        </div>
                        <!-- Lower Scale Units (Years) -->
                        <div class="gantt-header-row">
                            <div
                                class="gantt-cell"
                                v-for="(unit, index) in lowerScaleUnits"
                                :key="'lower-' + index"
                                :style="{
                                    left: unit.offsetDays * dayWidth + 'px',
                                    width: unit.durationDays * dayWidth + 'px'
                                }"
                            >
                                {{ unit.label }}
                            </div>
                        </div>
                    </div>

                    <!-- Milestone Names -->
                    <div
                        v-if="!editing"
                        class="milestone-names"
                        :style="{
                            width: milestoneNameWidth + 'px',
                            height: totalChartHeight + 'px'
                        }"
                    >
                        <div
                            v-for="(milestone, index) in milestones"
                            :key="milestone.m_ident"
                            class="milestone-name"
                            :style="{
                                top: headerHeight + index * rowHeight + 'px',
                                height: rowHeight + 'px'
                            }"
                        >
                            M{{ milestone.m_ident }}
                        </div>
                    </div>

                    <!-- Gantt Bars -->
                    <div
                        class="gantt-bars"
                        :style="{
                            left: !editing ? milestoneNameWidth + 'px' : '20px',
                            top: headerHeight + 'px',
                            width: totalChartWidth + 'px'
                        }"
                    >
                        <div
                            v-for="(milestone, index) in milestones"
                            :key="milestone.m_ident"
                            :style="{
                                position: 'absolute',
                                top: index * rowHeight + 'px',
                                height: rowHeight + 'px',
                                width: totalChartWidth + 'px'
                            }"
                            class="gantt-bar-c"
                        >
                            <div
                                v-if="
                                    milestone.start_date && milestone.end_date
                                "
                                class="gantt-bar gantt-bar__main"
                                :class="{ 'has-baseline': showBaseline }"
                                :style="[
                                    getBarStyle(milestone),
                                    { top: '5px' }
                                ]"
                                :title="
                                    `${milestone.name}: ${milestone.start_date} -
                        ${milestone.end_date}`
                                "
                            ></div>
                            <div
                                v-if="
                                    showBaseline &&
                                        baseline[index]['start_date'] &&
                                        baseline[index]['end_date']
                                "
                                class="gantt-bar gantt-bar__baseline"
                                :class="{ 'has-baseline': showBaseline }"
                                :style="[
                                    getBarStyle(baseline[index]),
                                    { top: '16px' }
                                ]"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.gantt-chart-container {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    user-select: none;
    cursor: grab;
}

.gantt-chart-container:active {
    cursor: grabbing;
}

.gantt-chart {
    position: relative;
}

.gantt-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

/* Header */
.gantt-header {
    position: absolute;
    top: 0;
    height: 40px; /* headerHeight */
    border: 1px solid green;
}

.gantt-header-row {
    position: relative;
    height: 20px;
}

.gantt-cell {
    position: absolute;
    border-top: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    text-align: center;
    font-size: 10px;
    background-color: $white;
    height: 20px;
    box-sizing: border-box;
}

// .gantt-cell {
//     position: absolute;
//     top: 0;
//     height: 40px; /* headerHeight */
//     border-left: 1px dotted $gray-600;
//     padding: 5px;
//     text-align: center;
//     font-size: 12px;

//     &__hdr_w {
//         font-size: 11px;
//         font-weight: 600;
//     }

//     &__hdr_y {
//         font-size: 10px;
//     }
// }

/* Milestone names */
.milestone-names {
    position: absolute;
    top: 0;
    left: 0;
}

.milestone-name {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: right;
    padding-right: 10px;
    font-size: 12px;
    line-height: 30px; /* Align text vertically */
    margin-top: 10px;
    border-top: 1px dotted $gray-600;
}

.gantt-bars {
    position: absolute;
    border-top: 1px dotted $gray-600;
    width: 100%;
    height: 100%;
}

.gantt-bar-c {
    border-bottom: 1px dotted $gray-600;
    width: 100%;
}

.gantt-bar {
    position: absolute;
    height: 22px;
    border-radius: 3px;

    &.has-baseline {
        height: 10px;
    }

    &__main {
        background-color: $default;
        opacity: 0.6;
    }

    &__baseline {
        background-color: $gray-300;
    }
}
</style>
