<script>
export default {
    props: {
        dependency: {
            type: String
        },
        output: {
            type: String,
            default: "short"
        }
    },
    computed: {
        parsed() {
            if (!this.dependency) {
                return {
                    milestone: "",
                    type: ""
                };
            }

            const regex = /^(\d+)([SE])$/;
            const match = this.dependency.match(regex);

            if (!match) {
                throw new Error(
                    "Invalid format. Expected format: ([0-9]+)[SE]"
                );
            }

            // Extracting the values
            const milestone = parseInt(match[1], 10); // The milestone number
            const type = match[2]; // 'S' or 'E' indicating start or end

            return { milestone, type };
        }
    }
};
</script>

<template>
    <span>
        <template v-if="!dependency">---</template>
        <template v-else>
            <template v-if="output === 'short'">
                <span class="text-primary">M{{ parsed.milestone }}</span
                ><span class="text-default">{{ parsed.type }}</span>
            </template>
            <template v-else-if="output === 'long'">
                <template v-if="parsed.type === 'S'">
                    {{ $t("milestones.dep_start_of") }}
                </template>
                <template v-else>
                    {{ $t("milestones.dep_end_of") }}
                </template>
                M{{ parsed.milestone }}
            </template>
        </template>
    </span>
</template>
