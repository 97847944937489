<script>
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import ProjectFlagService from "@/services/ProjectFlagService";
import VGanttRenderer from "@/components/Planning/Gantt/VGanttRenderer";
import VGanttEditor from "../Planning/Gantt/VGanttEditor";
import Cloner from "@/libraries/Cloner";
import VDependencyRenderer from "@/components/Planning/Helpers/VDependencyRenderer";
import MGConsts from "@/mixins/MGConsts";
import { EventBus } from "@/libraries/EventBus";
import { mapGetters } from "vuex";

export default {
    props: {
        projectId: {},
        forceRo: {
            type: Boolean,
            default: false
        }
    },
    mixins: [MGConsts],
    components: { VGanttRenderer, VDependencyRenderer, VGanttEditor },
    data() {
        return {
            isCreating: true, // isCreating = can create new req, otherwise a pending one is there
            showEditing: true,
            loading: true,
            milestones: [],
            errors: {},
            project_flag_id: null,
            flagSrc: {},
            ganttErrors: {},
            entity: {
                id: null,
                project_id: null,
                description: null,
                description2: null,
                subtype: "timeline_change",
                flag_type: "red",
                change_data: null,
                change_dna: null
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        canSave() {
            if (!this.entity.description) {
                return false;
            }

            if (!this.entity.description2) {
                return false;
            }

            if ("errors" in this.ganttErrors) {
                return false;
            }

            return true;
        },
        isRW() {
            if (this.loading === false) {
                if (this.forceRo) {
                    return false;
                }

                if (this.isCreating == true) {
                    return true;
                } else {
                    if (this.flagSrc.user_id == this.getMyId) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
    },
    async created() {
        this.entity.project_id = this.projectId;
        await this.fetchTimeline();
        this.loading = false;
        await this.checkPendingFlag();
    },
    methods: {
        /**
         * Get properly recalculated/adjusted data from the backend
         * @param pdata
         */
        async handlePreview(pdata) {
            try {
                const { data } = await ProjectMilestoneService.POST_preview({
                    ...pdata,
                    project_id: this.projectId
                });
                this.milestones = data.data;
                this.entity.change_data = Cloner.clone(this.milestones);
                this.entity.change_dna = Cloner.clone(data.meta.dna);
                this.ganttErrors = {};
            } catch (e) {
                this.ganttErrors = e.response.data;
                console.log("test et", e);
            }
        },
        // /**
        //  * This method is run on init:
        //  * - it checks whether we have any pending changes - if so - shows the read-only version
        //  * - otherwise it just fetches regular timeline and allows creation of a request
        //  */
        async checkPendingFlag() {
            try {
                const { data } = await ProjectFlagService.GET_hasPendingChanges(
                    this.projectId
                );

                if (data.data.project_flag_id > 0) {
                    this.project_flag_id = parseInt(data.data.project_flag_id);
                    const r = await ProjectFlagService.GETS(
                        this.project_flag_id
                    );

                    // Get the src flag
                    // and prepare the data here for further processing
                    this.flagSrc = r.data.data;
                    this.milestones = Cloner.clone(this.flagSrc.change_data);
                    this.entity.id = this.project_flag_id;
                    this.entity.change_data = Cloner.clone(this.milestones);
                    this.entity.change_dna = Cloner.clone(
                        r.data.data.change_dna
                    );
                    this.entity.description = Cloner.clone(
                        r.data.data.description
                    );
                    this.entity.description2 = Cloner.clone(
                        r.data.data.description2
                    );
                    this.ganttErrors = {};

                    this.isCreating = false;
                } else {
                    // Does not have a pending request
                    this.isCreating = true;
                }
            } catch (e) {
                console.log("cae e", e);
            }
        },
        /**
         * Save the form
         */
        async saveForm() {
            try {
                if (this.isCreating) {
                    await ProjectFlagService.POST(this.entity);
                    this.$notify({
                        message: this.$t("flagging.notify_flag_created"),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success"
                    });
                } else {
                    await ProjectFlagService.PUT(this.entity);
                    this.$notify({
                        message: this.$t("flagging.notify_flag_updated"),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success"
                    });
                }

                EventBus.$emit("project-refresh");
                EventBus.$emit("planning-refresh");
                this.$emit("close");
            } catch (e) {
                console.log("save e", e);
            }
        },
        /**
         * Fetch the timeline for presentation
         */
        async fetchTimeline() {
            try {
                const { data } = await ProjectMilestoneService.GET_timelineData(
                    this.projectId
                );
                this.milestones = data.data;
            } catch (e) {}
        },
        handleEdit(to_value) {
            this.showEditing = to_value;
        }
    }
};
</script>

<template>
    <modal
        size="full"
        :show="true"
        @close="$emit('close')"
        class="budget-modal"
        :loading="loading"
    >
        <template slot="header">
            <div>{{ $t("timeline.timeline_editor") }}</div>
        </template>

        <template slot="default">
            <div class="alert alert-info text-white text-bold" v-if="!isRW">
                {{ $t("planning.timeline_ro_view") }}
            </div>
            <FormulateForm v-model="entity" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-6">
                        <FormulateInput
                            name="description"
                            type="textarea"
                            :placeholder="$t('flagging.placeholder_impact')"
                            :label="$t('flagging.impact')"
                            :validation-name="$t('flagging.impact')"
                            validation="required"
                            rows="6"
                            :disabled="!isRW"
                        />
                    </div>
                    <div class="col-6">
                        <FormulateInput
                            name="description2"
                            type="textarea"
                            :placeholder="$t('flagging.placeholder_mitigation')"
                            :label="$t('flagging.mitigation')"
                            :validation-name="$t('flagging.mitigation')"
                            validation="required"
                            rows="6"
                            :disabled="!isRW"
                        />
                    </div>
                </div>

                <div class="row mt-4" v-if="isRW">
                    <div class="col-md-6 col-sm-12 col-xs-12 text-left">
                        <base-button type="danger" :outline="true" v-if="false"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            :disabled="!canSave"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>

            <!-- re-->
            <div class="separator-timeline"></div>

            <div class="mb-4">
                <h3>{{ $t("timeline.project_timeline") }}</h3>
            </div>

            <v-loader :loading="loading" v-if="loading" />
            <template v-else>
                <div class="g-r-c">
                    <div class="g-r-c__ctrl" v-show="showEditing">
                        <v-gantt-editor
                            :milestones="milestones"
                            :init-dna="entity.change_dna"
                            :is-r-w="isRW"
                            :errors="ganttErrors"
                            @preview="handlePreview"
                        />
                    </div>
                    <div class="g-r-c__graph">
                        <v-gantt-renderer
                            :milestones="milestones"
                            :editing="showEditing"
                            :show-baseline="true"
                            :errors="ganttErrors"
                            @show-editing="handleEdit"
                        />
                    </div>
                </div>
            </template>
        </template>
    </modal>
</template>

<style lang="scss" scoped>
.separator-timeline {
    border: 1px dashed $gray-500;
    margin-bottom: 30px;
    margin-top: 30px;
}

.g-r-c {
    display: flex;
    &__ctrl {
        flex: 0 0 350px;
    }

    &__graph {
        flex: 1 1;
        overflow: auto;
    }
}
</style>
